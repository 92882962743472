<template>
  <section>
    <div class="columns is-5-mobile is-3-desktop is-multiline is-centered">
      <div class="column is-10 is-10-mobile">
        <h1 class="title">Sucursales</h1>
        <h2 class="subtitle">
          Los Cortes. Smoked Brisket, Steaks &amp; Drinks
        </h2>
      </div>
      <div
        class="column is-5 is-10-mobile"
        v-for="restaurant in restaurants"
        :key="restaurant.title"
      >
        <div class="card card-shadow">
          <div class="card-image">
            <figure class="image is-1by1 has-bordered">
              <iframe
                class="has-ratio"
                width="100%"
                height="600"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                loading="lazy"
                marginwidth="0"
                :src="restaurant.url"
              ></iframe>
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <h3 class="title is-4">{{ restaurant.title }}</h3>
                <p class="subtitle is-6">{{ restaurant.address }}</p>
              </div>
            </div>
            <footer class="card-footer">
              <a
                :href="getWhatsappUrl(restaurant)"
                class="card-footer-item button is-primary"
                target="_blank"
              >
                <span class="icon is-small">
                  <i class="fab fa-whatsapp"></i>
                </span>
                <span>Ordenar</span>
              </a>
              <a
                :href="`tel:${restaurant.phone}`"
                class="card-footer-item button is-link"
                target="_blank"
              >
                <span class="icon is-small">
                  <i class="fas fa-phone-alt"></i>
                </span>
                <span>Llamar</span>
              </a>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    getWhatsappUrl(restaurant) {
      let message = `Hola, quiero ordenar en la sucursal ${restaurant.title}.`;
      return `https://wa.me/${restaurant.whatsapp}?text=${encodeURIComponent(
        message
      )}`;
    },
  },
  data() {
    return {
      restaurants: [
        {
          title: 'Sucursal "Plaza Carolinas, Salida Norte"',
          address:
            "Bulevar José limón 3641 Módulo 5 local 3 y 6, Humaya, 80020 Culiacán Rosales, Sin.",
          url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d57944.3169860406!2d-107.42033985244804!3d24.81189113268916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86bcdb9de078c7cb%3A0x96d3378cf335d3ce!2sLos%20Cortes%20AFZ.%20Smoked%20Brisket%2C%20Steaks%20%26%20Drinks!5e0!3m2!1ses-419!2smx!4v1638916885783!5m2!1ses-419!2smx",
          phone: "+526672857851",
          whatsapp: "+526674138519",
        },
        {
          title: "Sucursal Plaza Explanada",
          address:
            "Blvd Jesús Kumate Rodríguez 5388 Col. Las Flores Plaza Explanada Local E-13, 80104 Culiacán Rosales, Sin.",
          url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d57957.055735850656!2d-107.4267404487001!3d24.78463134234725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86bcd15ede137965%3A0x9d9c1a542d955798!2sLos%20Cortes%20AFZ.%20Smoked%20Brisket%2C%20Steaks%20%26%20Drinks!5e0!3m2!1ses-419!2smx!4v1638917009554!5m2!1ses-419!2smx",
          phone: "+526672196980",
          whatsapp: "+526674138519",
        },
        {
          title: 'Sucursal "La Primavera / Perisur"',
          address:
            "Av. Manuel J. Clouthier 374-A22, 22 de Diciembre, 80199 Culiacán Rosales, Sin.",
          url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48725.217685644384!2d-107.42033983686271!3d24.811765871321164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86bcd1b7a28563af%3A0xc4d230301c545d77!2sLos%20Cortes.%20Smoked%20Brisket%2C%20Steaks%20%26%20Drinks!5e0!3m2!1ses-419!2smx!4v1638916701555!5m2!1ses-419!2smx",
          phone: "+526677447940",
          whatsapp: "+526677447940",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.card-shadow {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.card-image {
  border-bottom: 2px solid #eaecee;
}
.card-footer-item {
  margin: 0 0.25rem;
}

@media only screen and (max-width: 769px) {
  .columns {
    display: flex;
  }
}
</style>
